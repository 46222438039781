.me-auto {
  margin-left: 550px;
}





body {
  background: linear-gradient(to right, #430079 -122%, #000000 100%);

  font-size: large;
  width: 100%;
  font-weight: 20px;

}



.as {
  height: 200px;

}

.Profile {
  display: inline-flex;
  padding: 40px;
  margin-top: -15px;

}

.Profile p {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-top: 70px;
  background: linear-gradient(to right, #00e1ff70 -2%, #050f1d4d 100%);
  width: 700px;
  box-shadow: 1px 5px 9px #d6d6d683;
  padding-left: 20px;
  padding-top: 20px;
  border-radius: 10px;


  color: rgb(255, 255, 255);
}

.h1 {
  color: white !important;
}

.View {
  margin-left: 520px;
  margin-top: 20px;
}

.birds {
  position: absolute;
  top: 50px;
  right: 10px;

}

Link {
  color: white !important;
}

.Profile img {
  margin-right: 10px;
  width: 200px;
  height: 200px;
  margin-top: 30px;
  margin-left: 30px;
  padding: 15px;
  border-right: #ffffff 2px solid;



}

.inside div {
  background-color: transparent;
  margin-left: 40px;
  height: 100px;

}

.inside div p {
  font-weight: bold;


  background-color: transparent;
  padding-left: 10px;
}

.icon {
  color: red !important;
}

.inside {
  width: 60%;
  height: 50px;
  background-color: transparent;
  display: inline-flex;
  padding: 0;
  justify-content: space-between;
  margin-left: 130px;

}



.CircularProgressbar {

  background-color: transparent;
  margin-top: 0px;

}

.skillbox {
  background: linear-gradient(to right, #00e1ff70 -2%, #050f1d4d 100%);
  box-shadow: 1px 5px 9px #d6d6d683;
  height: 220px;
  width: 70%;

  margin-left: 100px;
  border-radius: 90px;
  color: white;
}

.i11 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.i11:hover {
  cursor: pointer;
  box-shadow: 1px 2px 9px #ffffff;
  background-color: white;
  border-radius: 5px;


}

.popup {
  background: linear-gradient(to right, #e0c9e0f8 -2%, #0066ffdc 100%);
  height: 300px;
  z-index: 1;
  width: 80px;
  border-radius: 90px;
  position: absolute;
  display: flex;
  flex-direction: column;


  align-items: center;
  top: 45px;
  right: 60px;
}

.navbar {

  background: linear-gradient(to right, #430079 -122%, #000000 100%);


}




button {
  border: 0;
  background-color: rgb(102, 51, 153);
  border-radius: 5px;
  transition: 0.4s ease-in-out;
  color: white;



}

.contact {
  background: linear-gradient(to right, #cc00cc46 -2%, #0066ff73 100%);
  margin-top: 30px;
  display: inline-flex;


  width: 100%;
  height: 30px;
  padding: 0px;
  border-radius: 9px;
  overflow: hidden;

}

.i1 {
  margin-left: 20px;
  margin-right: 20px;
}

button:hover {
  background-color: rgb(53, 9, 97);


}

.bun {
  margin-left: 5px;
  color: white !important;

}

img {
  color: white;
}

.bun-active,
.bun:hover {
  border: 0.5px solid rgb(107, 1, 212);
  color: white !important;

  margin-left: 5px;
}

.banner {

  margin-top: 0;

  background-image: url('./assets/sa.jpg');

  height: 650px;
  width: 100%;

  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bannerText {
  background-color: transparent;

  margin-top: 190px;
  margin-left: 90px;
  color: #03387e;
  font-size: 50px;
  -webkit-text-stroke: 2px rgb(255, 255, 255);

  font-weight: bold;

}


.bannerText span {

  font-weight: bolder;
  font-family: 'Times New Roman', Times, serif;
  -webkit-text-stroke: 2px rgb(255, 255, 255);


  color: #000000;
  font-size: 70px;
  background-color: transparent;
}

.View {
  padding: 0;
  margin-top: 100px;
}

.View .icon {
  margin-bottom: 0;
}

.counter .allcounter {
  display: inline-flex;
}

.counter {
  display: inline-block;
  margin-left: 40px;
  margin-top: -30px;
  color: white;

}

.counter h3 {
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px;
}

th img {
  width: 300px;
  height: 180px;
  margin: 15px;
}

td img {
  width: 300px;
  height: 180px;
  margin: 15px;
}

td p {
  color: white;
  font-weight: bold;
  margin-left: 20px;
}

.Project {
  margin-bottom: 10px;
  overflow: hidden;
}

.Project h1 {
  color: white;
  font-weight: bold;
  margin-left: 30px;
}

.cards {
  background: linear-gradient(to right, #191b1b5b -2%, #38475c4d 100%);
  border-radius: 10px;
  margin-left: 30px;
  margin-top: 20px;
  box-shadow: 1px 2px 4px #cc00ff;

}

.cards:hover {
  box-shadow: 1px 4px 9px #a89eaa;

}

th p {
  color: white;
  margin-left: 25px;
}

.View span {
  font-weight: bold;
  font-size: 50px;



}

/* dfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff
fddddddddddddd
df


df
d

ddddddddddddddddddddddddddddddfffffffffffffffffffffffffffffffffffffffffffffffffffffffffff*/
@media (max-width: 600px) {
  body {
    background: linear-gradient(to right, #4a494b -122%, #000000 100%);

    font-size: large;
    width: 100%;
    font-weight: 20px;

  }

  .navbar {

    background: linear-gradient(to right, #878588 -122%, #000000 100%);
    z-index: 1;


  }

  .me-auto {
    margin-left: 0px;
  }

  .as {
    height: 200px;

  }

  .Profile {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin-top: 5px;

  }

  .Profile p {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: 30px;
    text-align: start;
    background: linear-gradient(to right, #00e1ff70 -2%, #050f1d4d 100%);
    width: 90%;
    box-shadow: 1px 5px 9px #d6d6d683;
    padding-left: 7px;
    margin-left: 5px;
    padding-top: 20px;
    border-radius: 10px;


    color: rgb(255, 255, 255);
  }

  .h1 {
    color: white !important;
  }

  .View {
    margin-left: 520px;
    margin-top: 20px;
  }

  .birds {
    position: absolute;
    top: 50px;
    right: 10px;

  }

  .Profile img {
    margin-right: 10px;
    width: 200px;
    height: 200px;
    margin-top: 30px;
    margin-left: 20%;
    padding: 15px;
    border-right: none;



  }

  .inside div {
    background-color: transparent;

    height: 10px;

  }

  .inside div p {
    font-weight: bold;
    font-size: 12px;


    background-color: transparent;
    padding-left: 0px;
  }

  .icon {
    color: red !important;
  }

  .inside {
    width: 90%;
    height: 50px;
    background-color: transparent;
    display: inline-flex;
    padding: 0;
    justify-content: space-between;
    padding-top: 30px;
    margin-left: 5px;

  }



  .CircularProgressbar {

    background-color: transparent;


  }

  .skillbox {
    background: linear-gradient(to right, #00e1ff70 -2%, #050f1d4d 100%);
    box-shadow: 1px 5px 9px #d6d6d683;
    height: 150px;
    margin-top: 20px;
    width: 100%;

    margin-left: 0px;
    border-radius: 90px;
    color: white;

  }

  .i11 {

    margin: 5px;

  }

  .i11:hover {
    cursor: pointer;
    box-shadow: 1px 2px 9px #ffffff;
    background-color: rgb(20, 20, 20);
    border-radius: 5px;


  }

  .popup {
    background: transparent;
    height: 50px;
    z-index: 1;
    width: 260px;
    border-radius: 90px;
    position: absolute;
    display: flex;
    flex-direction: row;




    align-items: center;
    justify-content: center;
    top: 205px;
    right: 20px;
  }






  button {
    border: 0;
    background-color: rgb(255, 255, 255) !important;
    border-radius: 5px;
    transition: 0.4s ease-in-out;
    color: rgb(0, 0, 0);



  }

  .contact {
    background: linear-gradient(to right, #cc00cc46 -2%, #0066ff73 100%);
    margin-top: 30px;
    display: inline-flex;


    width: 100%;
    height: 30px;
    padding: 0px;
    border-radius: 9px;
    overflow: hidden;

  }

  .i1 {
    margin-left: 20px;
    margin-right: 20px;

  }

  button:hover {
    background-color: rgb(247, 247, 247);


  }

  .bun {
    margin-left: 5px;
    color: white !important;

  }

  img {
    color: white;
  }

  .bun-active {
    border: 0.5px solid rgb(107, 1, 212);
    color: white !important;

    margin-left: 5px;
  }



  .bun:hover {
    border: none;
  }

  .banner {

    margin-top: 0;

    background-image: url('./assets/sa.jpg');

    height: 94vh;
    width: 100%;

    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bannerText {
    background-color: transparent;

    margin-top: 190px;
    margin-left: 10px;
    text-align: start;
    color: #f7f7f7;
    padding-left: 10px;
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
    width: 93%;
    border-radius: 14px;
    -webkit-text-stroke: transparent;

    background-color: #00000067;

    font-weight: bold;

  }


  .bannerText span {

    font-weight: bolder;
    font-family: 'Times New Roman', Times, serif;
    font-family: 'Courier New', Courier, monospace;
    -webkit-text-stroke: 1px white;


    color: #f39404;
    font-size: 40px;
    background-color: transparent;
  }

  .View {
    padding: 0;
    margin-top: 10px;
    margin-left: 30%;
  }

  .View .icon {
    margin-bottom: 0;
  }

  .counter .allcounter {
    display: inline-flex;
  }

  .counter {
    display: inline-block;
    margin-left: 0px;
    margin-top: -30px;
    color: white;

  }

  .counter h3 {
    font-weight: bold;
    margin-top: 20px;
    font-size: 20px;
  }

  th img {
    width: 300px;
    height: 180px;
    margin: 15px;
  }

  td img {
    width: 300px;
    height: 180px;
    margin: 15px;
  }

  td p {
    color: white;
    font-weight: bold;
    margin-left: 20px;
  }

  .Project {
    margin-bottom: 10px;
    margin-top: 80px;
  }

  .Project h1 {
    color: white;
    font-weight: bold;
    margin-left: 30px;
  }

  .cards {
    background: linear-gradient(to right, #191b1b5b -2%, #38475c4d 100%);
    border-radius: 10px;
    margin-left: 30px;
    margin-top: 20px;
    box-shadow: 1px 2px 4px #cc00ff;

  }

  .cards:hover {
    box-shadow: 1px 4px 9px #a89eaa;

  }

  th p {
    color: white;
    margin-left: 25px;
  }

  .View span {
    font-weight: bold;
    font-size: 50px;



  }


}